import React from 'react';
import buttonStyles from '../../styles/UI/Button.module.css';

const StandardButtonLong = ({
	onClick,
	children,
	disabled
}: {
	onClick?: () => void;
	children: React.ReactNode | React.ReactNode;
	disabled: boolean;
}) => {
	return (
		<button
			disabled={disabled}
			className={`${buttonStyles.standardButton} ${buttonStyles.blueButton}`}
			onClick={() => (onClick ? onClick() : null)}
		>
			{children}
		</button>
	);
};

export default StandardButtonLong;
