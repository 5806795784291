import { createSlice } from '@reduxjs/toolkit';
import { TransferBeltName, ArrivalBeltName, ArrivalBeltStatusDataObject } from '../../types';
import { getArrivalBeltStatus, updateArrivalBeltStatus } from './action';

type InitialState = {
	currentBelt?: ArrivalBeltName | TransferBeltName;
	currentBeltRef?: number;
	beltsWithScreening: TransferBeltName[];
	isScreeningOnCurrentBelt: boolean;
	selectedBelt?: ArrivalBeltName | TransferBeltName;
	selectedBeltRef?: number;
	arrivalBeltStatus: ArrivalBeltStatusDataObject[] | undefined;
	arrivalBeltStatusError?: string;
};

const initialState: InitialState = {
	arrivalBeltStatus: [],
	beltsWithScreening: [],
	isScreeningOnCurrentBelt: false
};

const beltSlice = createSlice({
	name: 'beltObject',
	initialState: initialState,
	reducers: {
		setCurrentBelt: (state, action) => {
			state.currentBelt = action.payload;
		},
		setScreeeningStateForBelts: (state, action) => {
			state.beltsWithScreening = action.payload;

			if (state.beltsWithScreening.includes(state.currentBelt as TransferBeltName)) {
				state.isScreeningOnCurrentBelt = true;
			}
		},
		setSelectedBelt: (state, action) => {
			state.selectedBelt = action.payload;
		},
		setCurrentBeltRef: (state, action) => {
			state.currentBeltRef = action.payload;
		},
		setSelectedBeltRef: (state, action) => {
			state.selectedBeltRef = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getArrivalBeltStatus.fulfilled, (state, action) => {
			delete state.arrivalBeltStatusError;
			if (action.payload) state.arrivalBeltStatus = action.payload.data;
		});
		builder.addCase(getArrivalBeltStatus.rejected, (state) => {
			state.arrivalBeltStatusError = 'An error occured when trying to connect to belt status.';
		});
		builder.addCase(updateArrivalBeltStatus.fulfilled, (state, action) => {
			delete state.arrivalBeltStatusError;
			if (action.payload) state.arrivalBeltStatus = action.payload.data;
		});
		builder.addCase(updateArrivalBeltStatus.rejected, (state) => {
			state.arrivalBeltStatusError = 'An error occured when trying to connect to belt status.';
		});
	}
});

export default beltSlice.reducer;

export const {
	setScreeeningStateForBelts,
	setCurrentBelt,
	setSelectedBelt,
	setCurrentBeltRef,
	setSelectedBeltRef
} = beltSlice.actions;
