const url = new URL(window.location.href);

export const pathname = document.location.pathname.split('/')[1];

// To do - come back and use instead of searchparams in files.
export const beltNameFromUrl = url.searchParams.get('belt');

class UrlParam {
	static beltParamInUrl = url.searchParams.get('belt');
	static beltRefParamInUrl = url.searchParams.get('beltref') || url.searchParams.get('beltRef');
	static gateParamInUrl = url.searchParams.get('gate');
	static baggageFactoryParamInUrl = url.searchParams.get('baggagefactory');
	static redirectUrl = url.href.split('#')[0];
	static isStaging = url.href.includes('cphxeustag') || url.href.includes('cphxeudev');
}

export default UrlParam;
