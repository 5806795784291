import { useEffect, useState } from 'react';
// Components
import { TableTitle } from '../index';
import { StandardButton, Timestamp } from '../../UI/index';
import { ReactComponent as FirstBagIcon } from '../../../assets/icons/xeu-blue-play.svg';

// Icons
import { ReactComponent as InBlockIcon } from '../../../assets/icons/xeu-blue-b.svg';
import { ReactComponent as WhiteStartIcon } from '../../../assets/icons/xeu-white-play.svg';

// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import buttonStyles from '../../../styles/UI/Button.module.css';
// types
import { BaggageEntryFlight, BaggageType, ErrorBlurb } from '../../../types';
import api, { API_PATHS } from '../../../service/api';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetFlightToAnimate, setFlightToAnimate } from '../../../store/animation';

export default function OEUTableRow({ flight }: { flight: BaggageEntryFlight }) {
	const dispatch = useAppDispatch();
	// @ts-ignore
	const [errorBlurb, setErrorBlurb] = useState<ErrorBlurb | undefined>();
	const baggageType: BaggageType = flight.flightRef.includes('ARRIVAL') ? 'PRIMARY' : 'TRANSFER';
	const flightToAnimate = useAppSelector((state) => state.animationStore.flightRef);

	useEffect(() => {
		if (flightToAnimate && flight.flightRef) {
			setTimeout(() => {
				dispatch(resetFlightToAnimate());
			}, 2000);
		}
	}, [flightToAnimate]);

	const startOddSize = async () => {
		setErrorBlurb(undefined);

		try {
			await api.post(API_PATHS.baggageOddSize, {
				scenarioRef: flight.scenarioRef,
				flightRef: flight.flightRef,
				baggageType: baggageType
			});
			dispatch(setFlightToAnimate(flight.flightRef));
		} catch (error) {
			setErrorBlurb({
				errorMessage: 'Something went wrong.',
				actionMessage: 'Please try again.'
			});
		}
	};

	return (
		<>
			<tr
				className={`${tableRowStyles.tableRow} ${
					tableRowStyles.eeuTableRow
				} table-font w-full-important flex items-center justify-center  ${
					flightToAnimate === flight.flightRef
						? tableRowStyles.animateXScalingOnReturnBehaviour
						: null
				}`}
			>
				<td className={`h-full flex ${tableRowStyles.tableRowBlock} w-[60%]`}>
					<TableTitle
						classes="w-[250px]"
						title={`${flight.airlineIdentifier} ${flight.flightNumber}`}
					/>
					<p className="font-secondary text-[var(--green-CPH-50)]">{flight.origins}</p>
					<div className="grey-dot m-[1rem]"></div>

					<InBlockIcon width={23} height={23} className="mr-2" />
					<Timestamp date={flight.inBlock} classes="text-[1.6rem]" />
					{flight.firstOddSizeBag && (
						<>
							<div className="grey-dot m-[1rem]"></div>
							<FirstBagIcon width={25} height={25} />
							<Timestamp date={flight.firstOddSizeBag} classes="text-[1.6rem]" />
						</>
					)}
				</td>

				<td
					className={`w-full-important h-full ${tableRowStyles.tableRowBlock} flex items-center justify-end `}
				>
					{errorBlurb && (
						<td>
							<div className={tableRowStyles.errorMessageWrapper}>
								<p className={tableRowStyles.errorMessage}>
									{errorBlurb.errorMessage}
									{errorBlurb.actionMessage}
								</p>
							</div>
						</td>
					)}
					{!flight.firstOddSizeBag && (
						<StandardButton
							classes={buttonStyles.blueButton}
							onClick={startOddSize}
							icon={<WhiteStartIcon height={25} width={25} />}
							title="Start"
						/>
					)}
				</td>
			</tr>
		</>
	);
}
