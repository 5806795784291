/* If name ends in UA it returns UA.
If it is an AEU flight it returns the last char, if the number is smaller than 10 - otherwise the last two chars.
If it is a TEU flight, it returns the last char.
 */
export const getBeltLabel = (name: string) => {
	if (name) {
		const lastTwoChars = name?.slice(-2);
		const secondLastChart = name?.charAt(name.length - 2);
		if (lastTwoChars.toLowerCase() === 'ua' || parseInt(secondLastChart)) {
			return lastTwoChars;
		} else {
			return name?.slice(-1);
		}
	} else {
		return '';
	}
};
