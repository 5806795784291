import { useAppSelector } from '../../../store/hooks';
import { useEffect, useState } from 'react';
// Components
import { Table } from '../index';
import { AEUTEUReallocationRow } from './index';
// Services
import {
	filterFlightsOnTransferStatus,
	filterAEUFlightsOnBeltRef
} from '../../../service/filterService';
import { sortAEUAndTEUFlights } from '../../../service/sortingService';
// Types
import {
	BaggageArrivalFlightState,
	BaggageTransferFlightState,
	TransferFlightStatusObject
} from '../../../types';

export const AEUTEUReallocationListView = ({
	baggageFlightState
}: {
	baggageFlightState: BaggageTransferFlightState | BaggageArrivalFlightState;
}) => {
	const { selectedBelt, selectedBeltRef } = useAppSelector((state) => state.beltStore);

	const [flightStatuses, setFlightStatuses] = useState<TransferFlightStatusObject>();
	const [sortedData, setSortedData] = useState<TransferFlightStatusObject>();

	useEffect(() => {
		if (baggageFlightState) {
			if (baggageFlightState !== undefined && selectedBelt) {
				// Only show data relevant to the current belt
				const filteredOnBelt = filterAEUFlightsOnBeltRef(baggageFlightState.data, selectedBeltRef);
				// Sort data according to completed and "incoming" flights
				const flightStatusObject = filterFlightsOnTransferStatus(filteredOnBelt);
				setFlightStatuses(flightStatusObject);
			}
		}
	}, [selectedBelt, baggageFlightState, selectedBeltRef]);

	useEffect(() => {
		if (flightStatuses) updateSortedFlights(flightStatuses);
	}, [flightStatuses]);

	const updateSortedFlights = (flightStatusObject: TransferFlightStatusObject) => {
		// sortFlights takes an array of BaggageFlight objects and sorts them according to inBlock
		const sortedFlights = sortAEUAndTEUFlights<TransferFlightStatusObject>(flightStatusObject);
		setSortedData(sortedFlights);
	};

	return (
		<>
			{sortedData && (
				<Table>
					<tbody>
						{sortedData.openFlightsCheckedIn.map((flight) => (
							<AEUTEUReallocationRow flight={flight} key={flight.flightRef} />
						))}
						{sortedData.openFlightsNotCheckedIn.map((flight) => (
							<AEUTEUReallocationRow flight={flight} key={flight.flightRef} />
						))}
					</tbody>
				</Table>
			)}
		</>
	);
};
