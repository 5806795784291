import styles from '../../styles/Header.module.css';
import { HeaderLabelContent } from '../../types';

export const HeaderLabel = ({ headerLabelContent }: { headerLabelContent: HeaderLabelContent }) => {
	return (
		<div
			className={styles.headerLabel}
			style={{
				backgroundColor: headerLabelContent.bgColor ? `var(${headerLabelContent.bgColor})` : 'none',
				color: `var(${headerLabelContent.fontColor})`,
				justifyContent: headerLabelContent.bgColor ? 'space-between' : 'center'
			}}
		>
			{headerLabelContent.icon} <p>{headerLabelContent.title}</p>
			{headerLabelContent.dotType && <div className={headerLabelContent.dotType}></div>}
			{headerLabelContent.beltLabel && <p>{headerLabelContent.beltLabel}</p>}
			{headerLabelContent.gateLabel && <p>{headerLabelContent.gateLabel}</p>}
		</div>
	);
};
