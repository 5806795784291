import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// Components
import { ReactComponent as BlueInfoIcon } from '../../../assets/icons/xeu-blue-information.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/xeu-white-arrow-right.svg';
// Store
import { openModal } from '../../../store/modalStore';
import { setSelectedBelt, setSelectedBeltRef } from '../../../store/belt';
// Services
import api, { API_PATHS } from '../../../service/api';
// Types
import { BaggageArrivalFlight, BaggageTransferFlight } from '../../../types';
// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { getBackgroundColorForAEUTEUTableRows } from '../../../service/tableRowBackgroundColorService';
import { setFlightToAnimate } from '../../../store/animation';
import InBlockTableBlock from './InBlockTableBlock';
import FirstBagTableBlock from './FirstBagTableBlock';
import TitleAndOriginTableBlock from './TitleAndOriginTableBlock';
import GreyDividerDot from '../../UI/GreyDividerDot';
import StandardButtonLong from '../../UI/StandardButtonLong';

export const AEUTEUReallocationRow = ({
	flight
}: {
	flight: BaggageArrivalFlight | BaggageTransferFlight;
}) => {
	const dispatch = useAppDispatch();
	const { currentBelt, currentBeltRef } = useAppSelector((state) => state.beltStore);
	const baggageType = useAppSelector((state) => state.pathStore.baggageType);
	const isScreeningOnCurrentBelt = useAppSelector(
		(state) => state.beltStore.isScreeningOnCurrentBelt
	);
	const [error, setError] = useState<string>();

	const moveFlightToNewBelt = () => {
		if (isModalWarningNeeded) {
			dispatch(openModal(flight));
		} else {
			handleMoveToCurrentBelt();
		}
	};

	// Handle move to current belt - this also happens in TransferModal.tsx
	const handleMoveToCurrentBelt = async () => {
		setError(undefined);
		try {
			const response = await api.post(API_PATHS.reallocate, state);
			if (response.status === 208) {
			} else if (response.status < 300) {
				dispatch(setFlightToAnimate(flight.flightRef));
				dispatch(setSelectedBelt(currentBelt));
				dispatch(setSelectedBeltRef(currentBeltRef!));
			}
		} catch (err: any) {
			setError(err.message);
		}
	};

	const state = {
		flightRef: flight.flightRef,
		baggageType: baggageType,
		fromBeltRef: flight.beltRef,
		toBeltRef: currentBeltRef
	};

	const isNeedScreening = flight.securityLevel === 'INTERNATIONAL' ? true : false;

	const [isModalWarningNeeded, setIsModalWarningNeeded] = useState<boolean>();

	useEffect(() => {
		if (isScreeningOnCurrentBelt && !isNeedScreening) {
			setIsModalWarningNeeded(true);
		} else setIsModalWarningNeeded(false);
	}, [isScreeningOnCurrentBelt, isNeedScreening]);

	const isFlightFulfillingScreeningRequirementsToBeMoved = () => {
		if (baggageType === 'PRIMARY') {
			return true;
		} else if (
			(isScreeningOnCurrentBelt && isNeedScreening) ||
			(!isScreeningOnCurrentBelt && !isNeedScreening) ||
			(isScreeningOnCurrentBelt && !isNeedScreening)
		) {
			return true;
		} else if (!isScreeningOnCurrentBelt && isNeedScreening) {
			return false;
		}
	};

	// Flights are only allowed to be moved, if it fulfills requirements set by airport (expressed by backend) and first is not set.
	const isMoveToNewBeltAllowed =
		isFlightFulfillingScreeningRequirementsToBeMoved() && !flight.actualFirstBag;

	return (
		<tr
			className={`${tableRowStyles.tableRow} ${
				tableRowStyles.aeuTeuTableRow
			} table-font ${getBackgroundColorForAEUTEUTableRows(flight)}`}
		>
			<td className="w-[75%] flex items-center" key="tableRow-1">
				<TitleAndOriginTableBlock flight={flight} />
				<GreyDividerDot />
				<InBlockTableBlock flight={flight} />
				{flight.actualFirstBag && !flight.noBax && (
					<>
						<GreyDividerDot />
						<FirstBagTableBlock flight={flight} />
					</>
				)}
			</td>
			<td className={`h-100 ${tableRowStyles.tableRowBlock} w-full-important justify-end`}>
				{error && (
					<div className="red-wrapper flex gap-3 rounded-full mr-3 p-3">
						<p>{error}</p>
					</div>
				)}
				{!isFlightFulfillingScreeningRequirementsToBeMoved() && (
					<div className="blue-wrapper flex gap-3 rounded-full mr-3 p-3">
						<BlueInfoIcon /> <p>Screening needed</p>
					</div>
				)}

				<StandardButtonLong disabled={!isMoveToNewBeltAllowed} onClick={moveFlightToNewBelt}>
					<ArrowIcon width={18} height={18} />
					Move to {currentBelt}
				</StandardButtonLong>
			</td>
		</tr>
	);
};
