import { useEffect, useState } from 'react';
// Components
import { StandardButton } from '../../UI/index';
// Icons
import { ReactComponent as WhiteStopIcon } from '../../../assets/icons/xeu-white-checkmark.svg';
import { ReactComponent as ResumeIcon } from '../../../assets/icons/xeu-white-running.svg';
import { ReactComponent as WhiteStartIcon } from '../../../assets/icons/xeu-white-play.svg';
import { ReactComponent as LoadingIcon } from '../../../assets/icons/xeu-progress_activity.svg';
import { ReactComponent as NoBaxIcon } from '../../../assets/icons/xeu-blue-bag.svg';
// Services
import {
	setActualFirstBag,
	setActualLastBag,
	setActualNoBax
} from '../../../service/baggageApiService';
// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import buttonStyles from '../../../styles/UI/Button.module.css';
import AEUTEUTableStyles from '../../../styles/AEUTEUTable.module.css';
// Types
import { BaggageArrivalFlight, BaggageState, BaggageTransferFlight } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getBackgroundColorForAEUTEUTableRows } from '../../../service/tableRowBackgroundColorService';
import { resetFlightToAnimate, setFlightToAnimate } from '../../../store/animation';
import ReallocatedTableBlock from './ReallocatedTableBlock';

import InBlockTableBlock from './InBlockTableBlock';
import FirstBagTableBlock from './FirstBagTableBlock';
import TitleAndOriginTableBlock from './TitleAndOriginTableBlock';
import LastBagTableBlock from './LastBagTableBlock';
import GreyDividerDot from '../../UI/GreyDividerDot';
import ReallocatedLastBagTableBlock from './ReallocatedLastBagTableBlock';

export const AEUTEUTableRow = ({
	flight,
	hideGhostData,
	isCompletedList,
	handleResumeClick
}: {
	flight: BaggageArrivalFlight | BaggageTransferFlight;
	hideGhostData?: boolean;
	isCompletedList?: boolean;
	handleResumeClick?: (flight: BaggageArrivalFlight | BaggageTransferFlight) => void;
}) => {
	const baggageType = useAppSelector((state) => state.pathStore.baggageType);
	const flightToAnimate = useAppSelector((state) => state.animationStore.flightRef);
	const dispatch = useAppDispatch();

	const [isFirstBagLoading, setIsFirstBagLoading] = useState(false);

	useEffect(() => {
		if (flightToAnimate && flight.flightRef) {
			setTimeout(() => {
				dispatch(resetFlightToAnimate());
			}, 2000);
		}
	}, [flightToAnimate]);

	// Ghost is only available on BaggageArrivalState, meaning that we need to check first of 'isGhjost' is in flight, before checking if isGhost is true.
	const isGhostAllocation = 'isGhost' in flight && flight.isGhost;

	const matchButtonToStatus = () => {
		if (isGhostAllocation) {
			return;
		}
		if (isFirstBagLoading) {
			return (
				<StandardButton
					onClick={() => {
						// No onClick action needed during isFirstBagLoading state
					}}
					title="Loading..."
					classes={AEUTEUTableStyles.loadingButton}
					icon={<LoadingIcon height={25} width={25} className={AEUTEUTableStyles.loadingIcon} />}
					disable
				/>
			);
		} else if (flight.actualFirstBag && !flight.actualLastBag) {
			return (
				<StandardButton
					onClick={setLastBag}
					title="Finish"
					classes={buttonStyles.blueButton}
					icon={<WhiteStopIcon height={20} width={20} />}
				/>
			);
		} else if (flight.actualFirstBag && flight.actualLastBag) {
			return (
				<StandardButton
					onClick={clearLastBag}
					title="Resume"
					classes={buttonStyles.blueButton}
					icon={<ResumeIcon height={23} width={23} />}
				/>
			);
		} else {
			return (
				<StandardButton
					onClick={setFirstBag}
					title="Start"
					classes={buttonStyles.blueButton}
					icon={<WhiteStartIcon height={25} width={25} />}
				/>
			);
		}
	};

	const [baggageErrorStatus, setBaggageErrorStatus] = useState<string | undefined>();

	useEffect(() => {
		const timer = setTimeout(() => {
			// rerender(baggageErrorStatus!);
			setBaggageErrorStatus(undefined);
		}, 5000);

		// Clear the timer if the component unmounts or before the effect runs again
		return () => clearTimeout(timer);
	}, [baggageErrorStatus]);

	const baggageState: BaggageState = {
		flightRef: flight.flightRef,
		baggageType: baggageType!
	};

	const setFirstBag = async () => {
		setIsFirstBagLoading(true);
		const result = await setActualFirstBag({ ...baggageState, setting: true });
		if (result.success) {
			setIsFirstBagLoading(false);
		} else {
			setBaggageErrorStatus(result.resultMessage);
		}
	};

	const setLastBag = async () => {
		const result = await setActualLastBag({ ...baggageState, setting: true });
		if (!result.success) setBaggageErrorStatus(result.resultMessage);
	};

	const setNoBax = async () => {
		const result = await setActualNoBax({ ...baggageState, setting: true });
		if (!result.success) setBaggageErrorStatus(result.resultMessage);
	};

	const removeNoBax = async () => {
		const result = await setActualNoBax({ ...baggageState, setting: false });
		if (!result.success) setBaggageErrorStatus(result.resultMessage);
	};

	const clearLastBag = async () => {
		if (flight.noBax) {
			removeNoBax();
		} else {
			const result = await setActualLastBag({ ...baggageState, setting: false });
			if (result.success && handleResumeClick) {
				handleResumeClick(flight);
				dispatch(setFlightToAnimate(flight.flightRef));
			} else {
				setBaggageErrorStatus(result.resultMessage);
			}
		}
		if (flight.noBax && handleResumeClick) {
			handleResumeClick(flight);
			dispatch(setFlightToAnimate(flight.flightRef));
		}
	};

	return (
		<tr
			className={`${tableRowStyles.tableRow} ${
				tableRowStyles.aeuTeuTableRow
			} table-font ${getBackgroundColorForAEUTEUTableRows(flight)} ${
				flightToAnimate === flight.flightRef ? tableRowStyles.animateScalingOnReturnBehaviour : null
			}`}
		>
			<td className="w-[75%] flex items-center" key="tableRow-1">
				<TitleAndOriginTableBlock flight={flight} />
				<GreyDividerDot />
				<InBlockTableBlock flight={flight} />
				{flight.actualFirstBag && !flight.noBax && (
					<>
						<GreyDividerDot />
						<FirstBagTableBlock flight={flight} />
					</>
				)}
				{flight.actualLastBag && !flight.noBax && !isGhostAllocation && (
					<>
						<GreyDividerDot />
						<LastBagTableBlock flight={flight} />
					</>
				)}
				{flight.noBax && (
					<>
						<GreyDividerDot />
						<div className={`h-100  ${tableRowStyles.tableRowBlock} w-[160px] justify-end`}>
							<NoBaxIcon width={25} height={25} />
							<p className="text-blue-CPH0 ml-3">No Bags</p>
						</div>
					</>
				)}
				{isGhostAllocation && (
					<>
						<GreyDividerDot />
						<ReallocatedLastBagTableBlock flight={flight} />
						<GreyDividerDot />
						<ReallocatedTableBlock />
					</>
				)}
			</td>
			{baggageErrorStatus && (
				<td>
					<div className={tableRowStyles.errorMessageWrapper}>
						<p className={tableRowStyles.errorMessage}>{baggageErrorStatus}</p>
					</div>
				</td>
			)}

			<td className={`flex items-center justify-end w-[25%] `} key="tableRow-2">
				{!isCompletedList &&
					!baggageErrorStatus &&
					!hideGhostData &&
					!flight.noBax &&
					(!flight.actualFirstBag || isFirstBagLoading) && (
						<StandardButton
							noBax={flight.noBax}
							onClick={setNoBax}
							title="No bags"
							classes={`mr-[20px] ${
								isFirstBagLoading
									? AEUTEUTableStyles.noBagsButtonLoadingState
									: buttonStyles.blueButton
							}`}
							disable={isFirstBagLoading}
						/>
					)}
				{flight.actualFirstBag && flight.actualLastBag && (
					<p className="completed-text">Completed</p>
				)}
				{matchButtonToStatus()}
				{flight.actualFirstBag &&
					!flight.actualLastBag &&
					!isGhostAllocation &&
					!isFirstBagLoading && (
						<div className={tableRowStyles.bagAnimationContainer}>
							<NoBaxIcon
								width={40}
								height={40}
								className={tableRowStyles.animatemoveBagAcrossSpace}
							/>
							<span className={tableRowStyles.animationBottomBar}></span>
						</div>
					)}
			</td>
		</tr>
	);
};
