import { ArrivalBeltName, TransferBeltName } from '../types';
import { ArrivalFlightStatusObject, FlightStatusObject } from '../types/common';

// used to sort Flights for FlightLists - expand type acceptance criteria to handle EAU and TEU if needed
export const sortFlights = (flightStatusObject: FlightStatusObject): FlightStatusObject => {
	const openEntryFlights = [...flightStatusObject.openFlights];
	const completedEntryFlights = [...flightStatusObject.completedFlights];

	return {
		openFlights: openEntryFlights.sort(
			// @ts-ignore
			(a, b) => new Date(a.inBlock) - new Date(b.inBlock)
		),
		completedFlights: completedEntryFlights.sort(
			// @ts-ignore
			(a, b) => new Date(b.inBlock) - new Date(a.inBlock)
		)
	};
};

export const sortAEUAndTEUFlights = <
	T extends {
		openFlightsNotCheckedIn: Array<{ inBlock: string }>;
		openFlightsCheckedIn: Array<{ inBlock: string }>;
		completedFlights: Array<{ actualLastBag: string }>;
	}
>(
	flightStatusObject: T
): T => {
	const openTransferFlightsNotCheckedIn = [...flightStatusObject.openFlightsNotCheckedIn];
	const openTransferFlightsCheckedIn = [...flightStatusObject.openFlightsCheckedIn];
	const completedTransferFlights = [...flightStatusObject.completedFlights];
	return {
		openFlightsNotCheckedIn: openTransferFlightsNotCheckedIn.sort(
			// @ts-ignore
			(a, b) => new Date(a.inBlock) - new Date(b.inBlock)
		),
		openFlightsCheckedIn: openTransferFlightsCheckedIn.sort(
			// @ts-ignore
			(a, b) => new Date(a.inBlock) - new Date(b.inBlock)
		),
		completedFlights: completedTransferFlights.sort(
			// @ts-ignore
			(a, b) => new Date(b.actualLastBag) - new Date(a.actualLastBag)
		)
	} as T;
};

export const simpleSortingByName = (
	incomingArray: (ArrivalBeltName | number)[] | TransferBeltName[]
) => {
	const arrayThatNeedsSorting = [...incomingArray];
	return arrayThatNeedsSorting.sort(function (a, b) {
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
	});
};
